<template>
  <v-card class="overflow-auto" elevation="0">
    <v-expansion-panels v-model="panel" :disabled="loading" multiple>
      <slot></slot>
    </v-expansion-panels>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: {
      required: true
    },
    currentPage: {
      required: true
    }
  },
  data() {
    return {
      panel: []
    };
  },
  computed: {
    textfilterSearch() {
      return this.$store.state.dataSet.textfilterSearch;
    },
    formatFilter() {
      return this.$store.state.dataSet.formatFilter;
    }
  },
  watch: {
    formatFilter(val, oldV) {
      if (val.includes('Excel')) {
        this.panel = [];
      }
    },
    textfilterSearch(value) {
      this.panel = [];
    },
    currentPage() {
      this.panel = [];
    }
  }
};
</script>
