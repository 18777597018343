<template>
  <v-btn small color="geoportal" class="white--text" :loading="loading" :disabled="!disabled" v-on="$listeners"
    >{{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: {
      required: true
    },
    loading: {
      required: true
    }
  },
  computed: {
    label() {
      if (this.loading) {
        return 'Abbrechen';
      }
      if (!this.disabled) {
        return 'Datensatz auswählen';
      } else {
        return 'Bestellung ausführen';
      }
    }
  }
};
</script>
