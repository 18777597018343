<template>
  <v-expansion-panel :readonly="!item.expandable" @change="openPanel(item.id)" class="v-expansion-panel-dataset">
    <v-row no-gutters>
      <v-col cols="auto">
        <v-checkbox
          class="pl-5"
          color="geoportal"
          hide-details
          :input-value="allSelected"
          :true-value="allSelected"
          :key="someSelected"
          :indeterminate="someSelected"
          :disabled="loading"
          @change="toggleAll"
        ></v-checkbox>
      </v-col>
      <v-col><slot></slot></v-col>
    </v-row>

    <v-expansion-panel-content class="subtitle-2">
      <slot name="loading"></slot>

      <v-alert v-if="errormessage" type="error" text>{{ errormessage }}</v-alert>
      <v-list flat :disabled="loading">
        <v-list-item-group multiple v-model="selected">
          <v-list-item v-for="feature in dataSetFeatures" :key="feature.id" :value="feature">
            <template v-slot:default="{ active }">
              <v-list-item-content>{{ feature.title }}</v-list-item-content>
              <v-chip-format
                :active="active"
                :filter="true"
                :icon="icon(feature)"
                :text="text(feature)"
              ></v-chip-format>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <span v-if="item.publisher" class="caption grey--text pl-4">Datenbereitsteller: {{ item.publisher }}</span>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import { format_icons as formatIcons } from '@/config';

import { IfFormatFilterFeatures } from '@/store/helpers/store-helpers';

import VChipFormat from '@/components/VChipFormat.vue';

export default {
  name: 'Dataset',
  components: {
    VChipFormat
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    formatFilter: {
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },

    value: {} //v-model in parent
  },
  data() {
    return {
      errormessage: '',
      isExpandet: false,
      isAllSelectedChecked: false,
      dataSetFeatures: undefined,
      selected: cloneDeep(this.value) || [],
      formatIcons: formatIcons
    };
  },

  computed: {
    allSelected() {
      const isSel =
        this.dataSetFeatures && this.dataSetFeatures.length && this.selected.length === this.dataSetFeatures.length;
      return isSel;
    },
    someSelected() {
      return this.selected.length > 0 && !this.allSelected;
    }
  },

  methods: {
    icon(feature) {
      return formatIcons[feature.format]?.mdi;
    },
    text(feature) {
      return formatIcons[feature.format]?.text || 'Datei';
    },
    async toggleAll(isAllSelectedChecked) {
      this.isAllSelectedChecked = isAllSelectedChecked;

      if (!this.item.expandable) {
        this.dataSetFeatures = [-1];
        this.updateSelection();
        return;
      }
      await this.getDatasetFeaturesById();
      this.updateSelection();
    },
    updateSelection() {
      this.selected = this.allSelected ? [] : this.dataSetFeatures || [];
    },
    async openPanel(dataSetId) {
      this.isExpandet = !this.isExpandet;

      if (this.isAllSelectedChecked) {
        this.selected = this.dataSetFeatures;
      }
      await this.getDatasetFeaturesById();
    },

    async getDatasetFeaturesById() {
      const datasetId = this.item.id;

      const features = await this.$store
        .dispatch('dataSet/getDatasetFeaturesById', {
          datasetId,
          format: this.datasetFormat
        })
        .catch(err => {
          this.errormessage = 'Es is ein Fehler aufgetreten';
        });

      if (!features) {
        this.errormessage = 'Es is ein Fehler aufgetreten';
        throw Error();
      }
      this.dataSetFeatures = [...features];
    }
  },
  watch: {
    value: {
      handler(value) {
        if (!isEqual(this.selected, value)) {
          this.selected = cloneDeep(value) || [];
        }
      },
      deep: true
    },
    async formatFilter(value, oldValue) {
      if (!this.item.expandable || !this.dataSetFeatures) {
        return;
      }
      await this.getDatasetFeaturesById();
    },
    selected(newV, oldV) {
      if (this.dataSetFeatures && this.selected.length === this.dataSetFeatures.length) {
        this.isAllSelectedChecked = true;
      } else {
        this.isAllSelectedChecked = false;
      }
      this.$emit('input', this.selected);
    }
  }
};
</script>
<style scoped>
.v-expansion-panel-dataset {
  margin-bottom: 10px;
}
.v-expansion-panel--active {
  border-left: 4px solid #afc500 !important;
}
.v-expansion-panel {
  border: 1px solid #eceff1;
}
</style>
