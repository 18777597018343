<template>
  <v-row>
    <v-col>
      <v-btn
        v-for="(item, idx) in filters"
        :key="idx"
        small
        outlined
        color="geoportal"
        class="grey--text ml-1 mb-1"
        :disabled="disabled"
      >
        {{ item.text || item }}
        <v-icon right @click="close(item)"> mdi-close </v-icon>
      </v-btn>
    </v-col>

    <v-col cols="auto">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    formatFilter: {
      required: true
    },
    currentOrderArea: {
      required: true
    },
    disabled: {
      required: true
    },
    loading: {
      required: true
    }
  },
  computed: {
    filters() {
      return this.currentOrderArea.map(item => item).concat(this.formatFilter || []);
    }
  },
  methods: {
    close(item) {
      if (item.text) {
        const segments = this.$store.state.dataSet.orderAreaFilter.filter(segment => segment.id !== item.id);
        this.$store.commit('dataSet/SET_ORDER_AREA_FILTER', segments);
        this.$emit('search');
        return;
      }
      const formats = this.formatFilter.filter(format => format !== item);
      this.$store.commit('dataSet/SET_FORMAT_FILTER', formats);
      this.$emit('search');
    }
  }
};
</script>
