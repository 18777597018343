<template>
  <!--<v-expansion-panel-header disable-icon-rotate>-->
  <v-expansion-panel-header :hide-actions="!expandable">
    <!--<template v-slot:actions>
      <v-chip-format
        :icon="formatIcon(format).mdi"
        :text="formatIcon(format).text"
        @click.stop="!expandable || null"
      ></v-chip-format>
    </template>-->
    <div class="ml-negative my-1" :class="{ 'my-2': expandable && formatFilter.length === 0 }">
      <!--<div >-->
      <!--<span class="mr-2">{{ title }}</span>-->
      <span>{{ title }}</span>
      <v-chip-format
        v-if="!expandable"
        :icon="formatIcon(format).mdi"
        :text="formatIcon(format).text"
        class="mx-3"
      ></v-chip-format>
      <template v-if="datasetFormat[0] !== 'Datei' && expandable">
        <v-chip-format
          v-for="(format1, idx) in datasetFormat"
          :key="idx"
          :icon="formatIcon(format1).mdi"
          :text="formatIcon(format1).text"
          class="mx-1"
        >
        </v-chip-format>
      </template>
    </div>
  </v-expansion-panel-header>
</template>
<script>
import { format_icons as formatIcons } from '@/config';
import VChipFormat from '@/components/VChipFormat.vue';
export default {
  components: {
    VChipFormat
  },
  props: {
    format: {},
    title: {},
    expandable: {},
    formatFilter: {
      required: true
    }
  },
  data() {
    return {
      formatIcons: formatIcons
    };
  },
  computed: {
    datasetFormat() {
      return !this.formatFilter.length ? [this.format] : this.formatFilter;
    }
  },
  methods: {
    formatIcon(format) {
      return this.formatIcons[format.toLowerCase()] || this.formatIcons['datei'];
    }
  }
};
</script>
<style scoped>
.ml-negative {
  margin-left: -20px;
}
</style>
