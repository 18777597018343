<template>
  <v-container class="pa-7">
    <template v-if="!datasetItems">
      <the-status-progress />
    </template>

    <v-alert color="warning" v-else-if="datasetItems.length === 0" text> keine Daten gefunden! </v-alert>

    <template v-else>
      <slot></slot>
      <filter-preview
        :formatFilter="formatFilter"
        :currentOrderArea="currentOrderArea"
        :disabled="hasSelectedItems"
        :loading="loading"
        @search="search"
      >
        <order-button @click="sendOrder" :disabled="hasSelectedItems" :loading="loading"> </order-button>
      </filter-preview>

      <dataset-container :loading="loading" :currentPage="currentPage">
        <dataset
          v-for="(item, i) in getPaginatedItems"
          :loading="loading"
          :key="i"
          :idx="i"
          :item="item"
          :formatFilter="formatFilter"
          :currentPage="currentPage"
          v-model="selectedFeatures[item.id]"
          @input="selectionChanged"
        >
          <dataset-title
            :title="item.title"
            :format="item.format"
            :expandable="item.expandable"
            :formatFilter="formatFilter"
          >
          </dataset-title>
          <template v-slot:loading>
            <the-status-progress />
          </template>
        </dataset>
      </dataset-container>

      <v-pagination v-model="currentPage" color="geoportal" :length="pages"></v-pagination>
    </template>

    <the-snackbar />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TheSnackbar from '@/components/TheSnackbar.vue';
import TheStatusProgress from '@/components/TheStatusProgress.vue';
import FilterPreview from '@/components/FilterPreview.vue';
import OrderButton from '@/components/OrderButton.vue';
import DatasetContainer from '@/components/DatasetContainer.vue';
import Dataset from '@/components/Dataset.vue';
import DatasetTitle from '@/components/DatasetTitle.vue';

import { drop } from 'lodash';

export default {
  components: {
    OrderButton,
    FilterPreview,
    DatasetContainer,
    Dataset,
    TheSnackbar,
    TheStatusProgress,
    DatasetTitle
  },
  created() {
    this.search();
  },
  computed: {
    getPaginatedItems() {
      const pg = this.currentPage;
      let pgSize = 5;
      let offset = (pg - 1) * pgSize;
      if (offset > this.datasetItems.length) {
        this.currentPage -= 1;
        let mod = this.datasetItems.length % 5;
        offset = this.datasetItems.length - mod;
      }

      const pagedItems = drop(this.datasetItems, offset).slice(0, pgSize);
      return pagedItems;
    },
    pages() {
      return Math.ceil(this.datasetItems.length / 5);
    },

    formatFilter() {
      return this.$store.state.dataSet.formatFilter;
    },

    datasetItems() {
      return this.$store.state.dataSet.cachedDatasets;
    },

    currentOrderArea() {
      return this.$store.state.dataSet.orderAreaFilter;
    },
    hasSelectedItems() {
      return !!this.getSelectedFeatures().length;
    }
  },
  data() {
    return {
      model: 0,
      loading: false,
      currentPage: 1,
      selectedFeatures: {}
    };
  },

  methods: {
    ...mapActions('order', ['order']),
    ...mapGetters({
      getUserSegments: 'orderArea/getUserSegments'
    }),

    search() {
      this.$store.dispatch('dataSet/search', {
        roles: this.$route.meta.roles
      });
    },
    selectionChanged() {
      this.$store.commit('dataSet/DATASET_ITEM_SELECTION_CHANGED', this.hasSelectedItems);
    },
    getSelectedFeatures() {
      return Object.keys(this.selectedFeatures).filter(id => this.selectedFeatures[id].length > 0);
    },
    getSegmentProp(prop) {
      if (!this.currentOrderArea) {
        return [];
      }
      return this.currentOrderArea.map(item => item[prop]);
    },
    createOrderPayload() {
      let selFeatures = this.getSelectedFeatures();
      return selFeatures.map(key => ({
        dataset: +key,
        features: this.selectedFeatures[key].filter(item => item.id).map(item => item.id),
        dimensions: [],
        segments: this.getSegmentProp('id')
      }));
    },

    async sendOrder() {
      this.loading = true;
      let payload = this.createOrderPayload();
      let orderArea = this.getSegmentProp('text');

      if (!orderArea.length) {
        orderArea = this.getUserSegments()(this.$route.name).map(item => item.text);
      }

      try {
        await this.order({
          payload,
          orderArea,
          selectedFeatures: this.selectedFeatures
        });
      } catch (error) {
        this.loading = false;
        throw Error('unerwarteter Fehler!');
      }

      this.loading = false;
      this.$router.push({ name: 'downloadCenter' });
    }
  }
};
</script>
