<template>
  <v-chip small :filter="filter" class="grey--text" v-model="active" active-class="geoportal" :outlined="active">
    <v-icon small left>{{ icon }}</v-icon>
    {{ text }}
  </v-chip>
</template>
<script>
export default {
  props: {
    icon: {
      required: true
    },
    text: {
      required: true
    },
    filter: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>
